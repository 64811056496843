import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class DagDTO
{
    name?: Nullable<string>; 
}

export default class Dag extends AbstractBaseEntity
{
    private name: Nullable<string>;

    constructor(
        name: Nullable<string>
    ) {
        super();
        this.name = name;
    }

    static create(dto: DagDTO): Dag
    {
        return new Dag(
            dto.name
        );
    }

    setId(name: string): void
    {
        this.name = name;
    }

    getId(): string
    {
        return this.name;
    }
}
