import { Nullable } from '@/core/domain/type/types'

export default class DagRunCommand
{
    private dagId: string;

    constructor(dagId: string)
    {
        this.dagId = dagId;
    }

    getClassName(): string
    {
        return 'DagRunCommand';
    }

    getDagId(): string
    {
        return this.dagId;
    }
}